.big-container {
  height: 100vh;
  width: 100vw;
}

.navbar-section {
  height: 7.5vh;
  background-color: #C7CCB9;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  overflow: hidden;
}

.timeline-section {
  height: 90vh;
  background-color: #C7CCB9;
}

.footer {
  height: 2.5vh;
  background-color: #C7CCB9;
}

.name-part,
.about-me-part,
.overview-part,
.timeline-part {
  margin-left: 10px;
  cursor: pointer;
  line-height: 1px;
}