.header {
    font-size: larger;
    height: 5vh;
}

.toggle-section {
    display: flex;
    justify-content: space-evenly;
    width: 50vw;
}

.content-section {

}

.timeline-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}